<template>
  <section class="product-groups">
    <h2 v-if="$slots.title" class="font font_title-l font_bold font_uppercase mb_title-l">
      <slot name="title" />
    </h2>
    <h2 v-else class="visually-hidden">
      Товары
    </h2>
    <LazyLoader
      v-if="productGroups.length"
      :loading="isPending"
      :next-page="canGetNextPage"
      @update="onLoaderUpdate"
    >
      <ul class="product-groups__list">
        <li
          v-for="(item, idx) in productGroups"
          :key="`pg-${item.id}-${idx}`"
          class="product-groups__item"
        >
          <Products v-if="isProduct(item) && item.items.length" :items="item.items" />
          <ProductGroup v-else-if="!isProduct(item)" :group="item" />
        </li>
      </ul>
      <div v-show="addingCartLoading" class="product-groups__overlay" />
    </LazyLoader>
    <p v-else-if="$slots.empty">
      <slot name="empty" />
    </p>
  </section>
</template>

<script>
import './index.css';

import { mapState } from 'vuex';
import { generateCategoryUrl } from '@/plugins/helpers/catalog';

import ProductGroup from '@/components/catalog/productGroup';
import LazyLoader from '@/components/elements/LazyLoader';
import Products from '@/components/catalog/products';

export default {
  name: 'ProductGroups',
  components: {
    ProductGroup,
    LazyLoader,
    Products,
  },
  props: {
    productGroup: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPending: false,
    }
  },
  computed: {
    ...mapState('cart', ['addingCartLoading']),
    productGroups() {
      const items = this.productGroup.items || [];
      const type = this.$route.name;

      return items.map((item) => ({
        ...item,
        url: generateCategoryUrl(type, item.code),
      }));
    },
    canGetNextPage() {
      if (this.productGroup.range) {
        return this.productGroup.items.length < this.productGroup.range;
      }
      return true;
    },
  },
  watch: {
    productGroup: {
      handler() {
        this.isPending = false;
      },
      deep: true,
    },
  },
  methods: {
    onLoaderUpdate() {
      this.isPending = true;
      this.$emit('get-items');
    },
    isProduct(item) {
      return item.type?.code === 'products';
    },
  },
};
</script>
