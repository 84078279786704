<template>
  <div class="product-feed">
    <LoaderDots v-if="$fetchState.pending" />
    <ProductGroups
      class="main-page__catalog"
      :product-group="products"
      @get-items="getNextPageItems"
    />
  </div>
</template>

<script>
import LoaderDots from '~/components/elements/LoaderDots.vue';
import ProductGroups from '~/components/catalog/productGroups/index.vue';

export default {
  name: 'ProductFeed',
  components: {ProductGroups, LoaderDots},
  data() {
    return {
      products: {},
    }
  },
  async fetch() {
    try {
      const response = await this.$api.catalog.getFeed();
      this.$set(this.products, 'items', response);
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async getNextPageItems() {
      try {
        const response = await this.$api.catalog.getFeed();
        response.forEach(item => {
          if (this.products?.items) {
            this.products.items.push(item)
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
}
</script>
